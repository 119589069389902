import styled from 'styled-components';
import {Modal, Tabs} from "react-bootstrap";
import {CustomDropDown} from "../PeopleHomeV2/styles";

const BoldFont = 'Rubik-Medium';
//const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';  
const StyledTabContainer = styled(Tabs)`
   float: left;
  > ul {
    height: ${({newPadding}) => newPadding ? '70px' : 'none' };
    border-bottom: none;
    padding: ${({newPadding}) => newPadding ? '16px 10px 12px 18px' : '20px 20px 1px 33px' };
    font-size: 12px;
    text-transform: capitalize;
    color: rgb(153,153,153);
    background-color: #fff;
    
    > li {
      > a {
        font-size: 12px;
        letter-spacing: 0px;
        color: rgb(153,153,153);
        font-family: ${BoldFont};
        margin-right: 5vw;
        text-transform: capitalize;
        padding: 5px 5px 17px 5px;
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border-color: transparent;
        }
      }
    }
    
    .active {
      border-bottom: none;
      > a {
        background-color: transparent;
        color: rgb(21,159,202);
        border: none;
        border-bottom: 3px solid rgb(21,159,202);
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border: none;
          border-bottom: 3px solid rgb(21,159,202);
          color: rgb(21,159,202);
        }
      }
    }
  }
`;

const StyledTabs = styled(StyledTabContainer)`
    .tab-content {
    position: relative;
    
    @media (max-width: 500px) {
      margin-top: -48px;
    }
  }
  
  .company-container {
    float: right;
    margin-top: -53px;
    margin-right: 43px;
    
    @media (max-width: 650px) {
      float: left;
      margin-left: 22px;
    }
    
    @media (max-width: 500px) {
        display: inline-block;
        float: none;
        margin: 0 auto;
    }
  }
  
  > ul {
    @media (max-width: 650px) {
      min-height: 135px
    }
    
    @media (max-width: 500px) {
      min-height: 200px;
    }
    
    // @media (max-width: 500px) {
    //   padding: 20px 10px 1px;
    // }
    > li {
      @media (max-width: 500px) {
        float: none;
        width: 135px;
        margin: 0 auto;
      }
      > a {
        font-size: ${({fontSize}) => fontSize ? fontSize : '15px' };
        //color: rgb(51,51,51);
        color: #CCCBDO;
        text-transform: none;
        font-family: ${MediumFont};
        
        @media (max-width: 500px) {
        padding-left: 0;
        padding-right: 0
        margin: 0
       }
      }
    }

    .active {
      > a {
        &:hover, &:active, &:focus {
          color: rgb(51,51,51);
        }
      }
    }
  }

  li.first-tab.active {
    > a {
      color: #0169b2
      border-bottom: 3px solid #0169b2;
      font-family: ${BoldFont}
    }
  }
  li.second-tab.active {
    > a {
      color: #0169b2
      border-bottom: 3px solid #0169b2;
      font-family: ${BoldFont}
    }
  }
  li.third-tab.active {
    > a {
      color: #0169b2
      border-bottom: 3px solid #0169b2;
      font-family: ${BoldFont}
    }
  }
  .dropdown-menu {
    min-width: 54px;
    width: 67px;
    > li {
        > a {
          padding: 5px 15px;
        }
      }
    > ul {
      max-width: 300px;
    }
  }
`;
const CustomYearDropDown = styled(CustomDropDown)`
  float: right;
  background-color: transparent;
  padding: 2px 10px 7px;
  box-shadow: none;
  @media (max-width: 750px){
    padding: 12px 10px 7px;
  }
  @media (max-width: 500px){
    padding: 22px 10px 7px;
  }
  button{
    display:flex !important;
    justify-content:space-between !important;
    align-items:center !important;
    width:90px !important;
    .dropdown-toggle::after {
    color:rgb(105, 194, 255) !important;
  }
    
  }
  .dropdown-toggle::after {
    color:rgb(105, 194, 255) !important;
  }
`;

const NoData = styled.div`
  width: ${({width}) => width ? width : '100%' };
  padding: 20px 0;
  text-align: center;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rubik";
  font-size: 18px;
  color: #005c87;
`;

const InitiativesContainer = styled.div`
  width: 100%;
  .initiative-icon {
   width: 140px;
   height: 140px;
   margin: auto;
   margin-bottom 25px;
    >img{
     width: 100%;
     height: 100%;
    }
  }
  .details{
    width: 100%;
    ${'' /* margin: auto; */}
    display: flex;
    justify-content: center;
    color:#005C87;
    text-align: center;
    font-family: Rubik-regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    padding: 0px 20px 0px 20px;
  }
  .details-initiative{
    font-family: 'Rubik-Bold';
    font-size: 18px; // 16px;
    line-height: 24px; // 25.8px;
    color: #005C87; // #0D4270;
  }
  .greatText{
    font-family: Rubik-Medium;
    font-size: 24px;
    line-height: 24px;
    color: ${({color}) => color ? color : '#0D4270'};
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .completionText{
    font-family: Rubik
    font-size: 16px;
    line-height: 16px;
    color:#0D4270;
    text-align: center;
    margin-bottom: 25px;
  }
  .initiativeText{
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 20px;
    color:#0D4270;
    margin-bottom: 15px;
    text-align: center;
  }
  .congratulationText{
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 20px;
    color: ${({color}) => color ? color : '#0D4270'};
    margin-bottom: 30px;
    text-align: center;
  }
`;

const ConfirmDetailsContainer = styled.div`
  width: 100%;
  padding: 0px 20px 0px 20px;
  .sub-heading-text {
    font-family: Rubik;
    font-size: 18px;
    line-height: 24px;
    color: #005C87;
    text-align: center;
  }
  .MuiSvgIcon-root {
    width: 28px !important;
    height: 42px !important;
  }

  .middle-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-size: 18px;
    color: #005c87;
    margin-bottom: 10px;
    font-weight: bold;
  }

  #event-upload-file {
    display: none;
  }

  .custom-upload-button {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 48px;
    background-color: #FFFFFF;
    color: #007AB1;
    border: 1px solid #007AB1;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
    line-height: 20px;
    font-family: rubik;
    justify-content: center;
  }

  // .custom-upload-button:hover {
  //   background-color: #003f5c;
  // }

  .custom-upload-button svg {
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }

  .confirm-details-container{
    margin-top: 25px;
    margin-bottom: 15px;
  }

`

const ButtonContainer = styled.div`
  width: 100%;
  justify-conatent: center;
  margin: auto;
`;

const ConfirmButton = styled.div`
width: ${({width}) => width ? width : '352px'};
height: 48px;
background: ${({background}) => background ? background :'linear-gradient(90deg, rgb(0, 111, 188) -1.11%, rgb(127, 67, 255) 97.46%)'};
margin: auto;
display: flex;
justify-content: center;
align-items: center;
border-radius: 4px;
cursor: pointer;
margin: ${({margin}) => margin ? margin :'25px auto 5px auto'};
font-family: 'Rubik-Medium';
font-size: 18px;
line-height: 24px;
color: #FFFFFF;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    transform: ${({transform}) => transform ? 'translate(0, 15vh) translate(0, -15%) !important' : 'translate(0, 50vh) translate(0, -50%) !important'};
    margin: ${({change}) => change ? '150px auto' : '0 auto'};
    @media (max-height: 460px) {
      transform: none !important;
      margin: 30px auto;
    }
    width: ${({width}) => width ? width : '490px'};
    height: ${({change, height}) => change ? '573px' : height ? height : '473px'};
    @media (max-width: 500px){
      width: 95%;
      height: auto;
    }
  }

  .modal-body{
    padding: 25px 0 25px 0;
  }
  
  .modal-container {
   width: 100%;
   display: block;
   > div: nth-child(1) {
    width: 100%;
    height: 0px;
    display: flex;
    justify-content: flex-end;
    >img{
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin: 15px 15px 0 0;
    }
   }
   > div: nth-child(2) {
    width: 100%;
    display:flex;
    justify-content: center;
    font-family: Rubik-Medium;
    font-size: 24px;
    line-height: 24px;
    color:#0D4270;
    text-decoration: underline;
    margin-top: 35px;
   }
  }
`;

export {
  StyledTabs, CustomYearDropDown, StyledTabContainer, NoData, InitiativesContainer,ConfirmButton, ConfirmDetailsContainer, ButtonContainer, StyledModal
};